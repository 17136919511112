<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-item-sliding
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item class="hydrated link">
        <ion-label @click="openPopover(item, $event)">
          <h2>
            {{ item.name }}
            <span style="color: grey">({{ item.exam_type.name }})</span>
          </h2>
          <ion-note>{{ item.description }}</ion-note>
        </ion-label>
      </ion-item>
    </ion-item-sliding>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No exams found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="'Exam actions'"
    >
      <!-- <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.schedule',
          params: {
            exam: selectedResource.id,
            title: `${selectedResource.name} (${selectedResource.exam_type.name})`,
          },
        }"
        >Schedule</ion-item
      > -->
      <!-- <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.section-standard',
          params: {
            exam: selectedResource.id,
            title: `${selectedResource.name} (${selectedResource.exam_type.name})`,
          },
        }"
        >Classes</ion-item
      > -->
      <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.subject',
          params: {
            exam: selectedResource.id,
            title: `${selectedResource.name} (${selectedResource.exam_type.name})`,
          },
        }"
        >Subjects</ion-item
      >
      <!-- <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.control',
          params: {
            exam: selectedResource.id,
            title: `${selectedResource.name} (${selectedResource.exam_type.name})`,
            exam_type: selectedResource.exam_type.name,
          },
        }"
        >Exam Control</ion-item
      > -->
      <!-- <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.user',
          params: {
            exam: selectedResource.id,
            title: `${selectedResource.name} (${selectedResource.exam_type.name})`,
          },
        }"
        >Students</ion-item
      > -->
      <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'exam.result',
          params: {
            exam: selectedResource.id,
            title: `${selectedResource.name} (${selectedResource.exam_type.name})`,
            exam_type: selectedResource.exam_type.name,
            exam_name: selectedResource.name,
          },
        }"
        >Result</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import BasePopover from "../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonNote,
  IonItemSliding,
  IonItem,
  IonPopover,
  alertController,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
  ellipsisHorizontalOutline,
  ellipsisHorizontalSharp,
} from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonNote,
    IonItemSliding,
    IonItem,
    IonPopover,
    BasePopover,
  },
  data() {
    return {
      trashOutline,
      trashSharp,
      createOutline,
      createSharp,
      ellipsisHorizontalOutline,
      ellipsisHorizontalSharp,

      updateModalState: false,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      resourceToUpdate: {
        id: null,
        name: null,
      },

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  methods: {
    slide(id) {
      this.$refs[`items-${id}`].$el.open();
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Delete subject group ${resource.name} ?`;
      const message = "This action cannot be undone.";
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);

      alertDialog.present();
    },

    async delete(data) {
      try {
        await this.$store.dispatch("subject/deleteResource", data);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },
  },
};
</script>

<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <template v-slot:search-bar>
      <ion-toolbar>
        <ion-segment value="current" @ionChange="segmentChanged($event)">
          <ion-segment-button value="current">
            <ion-label>Current</ion-label>
          </ion-segment-button>
          <ion-segment-button value="previous">
            <ion-label>Previous</ion-label>
          </ion-segment-button>
          <ion-segment-button value="all">
            <ion-label>All</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-exam :resources="resources"></list-exam>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import ListExam from "../../Components/Exam/ListExam.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import { funnelOutline } from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    ListExam,
    IonGrid,
    IonRow,
    IonCol,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "View Exams",
      backLink: "exam",
      reloadable: true,

      // createModalState: false,
      // filterModalState: false,

      funnelOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["exam/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("exam/resources", { refresh: refresh });
    },
    async loadData(event) {
      await this.fetch();
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("exam/resources", { refresh: true });
      if (event) {
        event.target.complete();
      }
    },
    async segmentChanged(event) {
      await this.$store.dispatch("exam/segment", {
        segment: event.target.value,
        exam_id: this.exam,
      });
    },
  },

  async created() {
    await this.fetch();
  },
};
</script>

<style scoped>
</style>
